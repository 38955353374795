import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, ThemeProvider, CssBaseline } from '@mui/material';
import getTheme from './theme'; // Adjust the path based on where your theme.js is located
import logoImage from './TP_logoW1.png';

const Login = () => {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const correctPassword = 'tpoints'; // Reminder: Securely handle authentication

  const handleLogin = () => {
    if (password === correctPassword) {
      navigate('/landing');
    } else {
      alert('Wrong password!');
    }
  };

  return (
    <ThemeProvider theme={getTheme()}>
      <CssBaseline />
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
      <img src={logoImage} alt="Logo" style={{ maxWidth: '300px', marginBottom: '20px' }} />
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          placeholder="Enter Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleLogin}>
          Login
        </Button>
      </Box>
    </ThemeProvider>
  );
};

export default Login;
