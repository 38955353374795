// src/theme.js
import { createTheme } from '@mui/material/styles';

const getTheme = () => createTheme({
  palette: {
    mode: 'dark', // Web3/AI themes often favor dark modes
    primary: {
      main: '#0f9d58', // A neon-like color for primary actions, adjust as needed
    },
    background: {
      default: '#121212', // Dark background
      paper: '#242424',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    button: {
      textTransform: 'none', // More modern look without uppercase
    },
  },
  // Add other theme customizations as needed
});

export default getTheme;
