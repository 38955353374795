import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';

const items = [
  {
    icon: <ViewQuiltRoundedIcon />,
    title: 'Movement Technique',
    description:
      'Perfect your form and technique with our real-time feedback tool, minimizing the risk of injury and maximizing efficiency.',
    imageLight: 'url("/static/images/templates/templates-images/dash-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/dash-dark.png")',
  },
  {
    icon: <EdgesensorHighRoundedIcon />,
    title: 'Performance Testing',
    description:
      'Assess your strength, endurance, and power with our comprehensive testing tool, tailored to your specific sport or activity.',
    imageLight: 'url("/static/images/templates/templates-images/mobile-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/mobile-dark.png")',
  },
  {
    icon: <DevicesRoundedIcon />,
    title: 'Balance Measurement',
    description:
      'Achieve optimal stability and control with our balance measurement tool, essential for injury prevention and performance enhancement.',
    imageLight: 'url("/static/images/templates/templates-images/devices-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
  },
  {
    icon: <DevicesRoundedIcon />,
    title: 'Mobility Assesment',
    description:
      'Unlock your full range of motion with our mobility measurement tool, ensuring you move freely and efficiently in all directions.',
    imageLight: 'url("/static/images/templates/templates-images/devices-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
  },
  {
    icon: <DevicesRoundedIcon />,
    title: 'Movement Velocity Feedback',
    description:
      'Unleash your speed potential with our tool that accurately measures and analyzes your movement velocity, helping you outpace the competition.',
    imageLight: 'url("/static/images/templates/templates-images/devices-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
  },
  {
    icon: <DevicesRoundedIcon />,
    title: 'Movement ',
    description:
      'Stay on track with your training goals with our tool that monitors the quality and consistency of your exercise delivery, ensuring you get the most out of every session.',
    imageLight: 'url("/static/images/templates/templates-images/devices-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
  },
];

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  

  return (
    <Container id="features" sx={{ mt: 0, pb: { xs: 8, sm: 16 } }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h4" color="text.primary">
            Product features
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ mb: { xs: 2, sm: 4 } }}
          >
            Start your journey with Smart Sports today and experience the difference our tools can make in your training and performance.
          </Typography>
        </Grid>
        {items.map(({ icon, title, description }, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              variant="outlined"
              component={Button}
              onClick={() => handleItemClick(index)}
              sx={{
                p: 3,
                height: '100%',
                background: 'none',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.05)', // 5% white transparent
                },
                backgroundColor:
                  selectedItemIndex === index ? 'action.selected' : undefined,
                borderColor: 'primary.main', // Set border color to the button color
              }}
            >
             <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center', // Align text in the center
                  gap: 2.5,
                  textAlign: 'center', // Align text in the center
                }}
              >
                <Box
                  sx={{
                    color: 'primary.main'
                  }}
                >
                  {icon}
                </Box>
                <Typography
                  color="text.primary"
                  variant="body2"
                  fontWeight="bold"
                >
                  {title}
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  sx={{ my: 0.5 }}
                >
                  {description}
                </Typography>
                <Link
                  color="primary"
                  variant="body2"
                  fontWeight="bold"
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    '& > svg': { transition: '0.2s' },
                    '&:hover > svg': { transform: 'translateX(2px)' },
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <span>Learn more</span>
                  <ChevronRightRoundedIcon
                    fontSize="small"
                    sx={{ mt: '1px', ml: '2px' }}
                  />
                </Link>
              </Box>

            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
