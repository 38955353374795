import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage';
import TryPage from './TryPage'; // Assuming you have a TryPage component for the /try route
import Login from './Login';
import ScanPage from './ScanPage';
import Log from './Log';
import SignUp from './SignUp';
import TrainingPointPage from './TrainingPointPage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TrainPage from './TrainPage';
import CmjPage from './CmjPage';
import VideoRecorder from './VideoRecorder';




const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    // You can customize other theme properties here
  },
});


function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />  
          <Route path="/landing" element={<LandingPage />} />
          <Route path="/log" element={<Log />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/trainingpoint" element={<TrainingPointPage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/train" element={<TrainPage />} />
          <Route path="/try" element={<TryPage />} />
          <Route path="/cmj" element={<CmjPage />} />
          <Route path="/Recorder" element={<VideoRecorder />} />
          <Route path="/scanpage" element={<ScanPage />} />
        </Routes>
      </div>
     </Router>
     </ThemeProvider> 
  );
}

export default App;
